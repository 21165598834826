export default class GradingScales {
	constructor(api) {
		this.api = api;
	}

	getGradingScales() {
		return this.api.processRequest("GET", "/grading_scales");
	}

	getGradingScale(id) {
		return this.api.processRequest("GET", `/grading_scales/${id}`);
	}

	deleteGradingScale(id) {
		return this.api.processRequest("DELETE", `/grading_scales/${id}`);
	}

	createGradingScale(title, comment, parts) {
		return this.api.processRequest("POST", "/grading_scales", {
			data: {
				title: title,
				comment: comment,
				parts: parts,
			},
		});
	}

	deletePart(id) {
		return this.api.processRequest("DELETE", `/grading_scales/parts/${id}`);
	}

	updatePart(id, order, comment) {
		return this.api.processRequest("PUT", `/grading_scales/parts/${id}`, {
			data: {
				order: order,
				comment: comment,
			},
		});
	}

	createPart(gradingScaleId, parts) {
		return this.api.processRequest(
			"POST",
			`/grading_scales/${gradingScaleId}/parts`,
			{
				data: {
					parts: parts,
				},
			}
		);
	}

	updateGradingScale(id, title) {
		return this.api.processRequest("PUT", `/grading_scales/${id}`, {
			data: {
				title: title,
			},
		});
	}
}
